<template>
  <Page>
    <template v-slot:content>
      <Comparator />
    </template>
  </Page>
</template>

<script>
import { Page } from "../../templates";
import { Comparator } from "../../organisms";

export default {
  components: {
    Page,
    Comparator
  }
};
</script>

<style lang="css" scoped src="./ComparatorPage.css"></style>
